;var ready = function() {

	$('.js-logo').hover(function() {
		$('link[rel$=icon]').remove();
		$('head').append($('<link rel="shortcut icon" type="image/x-icon"/>').attr('href', "/favicon32.png"));
	}, function() {
		$('link[rel$=icon]').remove();
		$('head').append($('<link rel="shortcut icon" type="image/x-icon"/>').attr('href', "/favicon32-black.png"));
	});

	$(window).resize(updateLayout);

	setInterval(function() {
		updateLayout();
	}, 1000)

	var $fotoramaDiv = $('#fotorama').fotorama();
	var fotorama = $fotoramaDiv.data('fotorama');

	$('.article-image-caption').click(function(e) {
		e.preventDefault();

		fotorama.show($(this).index());

		$('.article-image-caption').removeClass('active');
		$(this).addClass('active');

	});

};

function updateLayout() {

	$('.footer').css('height', $(window).height() + 'px');
	var hiMargin = ($(window).height() - $('.hi').height() - 120) / 2;
	$('.hi').css('margin-top', hiMargin + 'px');

	// $('.hi-caption').text($(window).height());
}

$(document).ready(ready);